import { postData, getData, putData } from './index';
import { DispatchType } from '../types';
import {
  AUTH_SIGNIN,
  AUTH_GET_PROFILE,
  AUTH_LOGOUT,
  AUTH_LOADING,
  AUTH_ERROR,
  AUTH_MESSAGE,
  SET_NOTIFS_IS_ACTIVE,
  PUT_USER,
} from './actions';
import {
  IUser,
  ISignIn,
  IForgotPassword,
  IResetPassword,
} from '../types/auth.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const signIn = async (dispatch: DispatchType, data: ISignIn): Promise<any> => {
  const url = '/auth/login';
  let user:IUser | null = null;
  let token = null;
  dispatch({
    type: AUTH_LOADING,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await postData(AUTH_ERROR, url, dispatch, data, false).then((response: any) => {
    user = response.data.user;
    token = response.data.token;
  });
  dispatch({
    type: AUTH_SIGNIN,
    payload: { user, token },
  });
  return promise;
};

export const forgotPassword = async (dispatch: DispatchType, data: IForgotPassword)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
: Promise<any> => {
  const url = '/auth/forgot-password';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await postData(AUTH_ERROR, url, dispatch, data, false).then((response: any) => {
    dispatch({
      type: AUTH_MESSAGE,
      payload: response.data,
    });
  });
  return promise;
};

export const resetPassword = async (dispatch: DispatchType, data: IResetPassword)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
: Promise<any> => {
  const url = `/auth/reset-password/${data.token}`;
  const promise = await postData(AUTH_ERROR, url, dispatch, { password: data.password }, false)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any) => {
      dispatch({
        type: AUTH_MESSAGE,
        payload: response.data,
      });
    });
  return promise;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProfileAction = async (dispatch: DispatchType): Promise<any> => {
  const url = '/profile';
  dispatch({
    type: AUTH_LOADING,
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await getData(AUTH_ERROR, url, dispatch, true);
  if (response.data.user) {
    dispatch({
      type: AUTH_GET_PROFILE,
      payload: response.data.user,
    });
  }
};

export const putFavoritesAction = async (
  dispatch: DispatchType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const url = '/profile/favorite';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promise = await putData(AUTH_ERROR, url, dispatch, data, true).then((response: any) => {
    dispatch({
      type: PUT_USER,
      payload: response.data.user,
    });
  });
  return promise;
};

export const logout = (dispatch: DispatchType): void => {
  dispatch({
    type: AUTH_LOGOUT,
  });
};

export const notifsToggleAction = (dispatch: DispatchType): void => {
  dispatch({
    type: SET_NOTIFS_IS_ACTIVE,
  });
};
