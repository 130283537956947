import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { MdPlayArrow } from 'react-icons/md';

import { ErrorField, InputEmail, InputPassword } from '../../lib/HooksFormFields';

import { TOOL_ID } from '../../constants';
import { signIn, getProfileAction } from '../../actions/authActions';

import logo from '../../assets/images/logo-octasmart.svg';
import login from '../../assets/images/login.png';
import loginMobile from '../../assets/images/login-mobile.png';
import styles from './Auth.module.scss';
import { ApplicationState } from '../../types';
import { AUTH_RESET_ERROR } from '../../actions/actions';

type FormValues = {
  email: string,
  password: string,
};

function Login(): JSX.Element {
  const { error, user, userUrl } = useSelector((state : ApplicationState) => state.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const resetError = useCallback(() => {
    dispatch({ type: AUTH_RESET_ERROR });
  }, [dispatch]);

  const getProfile = useCallback(() => {
    getProfileAction(dispatch);
  }, [dispatch]);

  function submitLogin(values : FormValues) {
    const data = {
      ...values,
      tool: TOOL_ID,
    };
    return signIn(dispatch, data);
  }

  useEffect(() => {
    resetError();
  }, [resetError]);

  useEffect(() => {
    if (user) {
      getProfile();
    }
  }, [user, getProfile]);

  useEffect(() => {
    if (userUrl) {
      const url = userUrl === '/tool-octapharma' ? '/tool-octapharma?category=0' : '/tool-doctor?category=0';
      navigate(url);
    }
  }, [userUrl, navigate]);

  return (
    <div className={styles.container}>
      <div className={`${styles.col} ${styles.form}`}>
        <img src={logo} alt="logo" />
        <p className="text-primary">
          Rejoignez notre communauté et
          <br />
          découvrez l’ensemble de nos services.
        </p>
        <form
          className={styles.signin}
          onSubmit={handleSubmit(submitLogin)}
        >
          <div className={styles['form-group']}>
            <InputEmail
              name="email"
              control={control}
              required
              label="Email"
              placeholder="Saissisez votre email..."
            />
            {errors.email && <ErrorField message={errors.email.message} />}
          </div>
          <div className={styles['form-group']}>
            <InputPassword
              name="password"
              control={control}
              required
              requiredMessage="Veuillez saisir votre mot de passe"
              label="Mot de passe"
              placeholder="Saissisez votre mot de passe..."
            />
            {errors.password && <ErrorField message={errors.password.message} />}
          </div>

          <Link to="/forgot" className={styles.forgot}>Mot de passe oublié ?</Link>

          <button
            type="submit"
            className={styles.submit}
          >
            <p>Je me connecte</p>
            <MdPlayArrow size={22} />
          </button>

          {error !== '401' && <p className={styles.error}>{error}</p>}

        </form>
        <div className={`${styles['illu-mobile']}`}>
          <img src={loginMobile} alt="illu-login" />
        </div>
        <p className={styles.legal}>
          Les informations recueillies font l’objet d’un traitement
          informatique chez OCTAPHARMA France.
          Le responsable de ces données est Octapharma France.
          Ces informations seront conservées pendant
          une durée de 3 ans à compter du dernier contact.
          A l’issue de ce délai, elles seront rendues anonymes et conservées
          à des fins de statistique et d’amélioration du service.
          Conformément à la réglementation sur la protection des données personnelles,
          vous avez un droit d’accès, de rectification,
          d’opposition, d’effacement et de limitation du traitement des données vous concernant,
          pour exercer ces droits,
          vous devez adresser votre demande à
          <a href="mailto:FR2DPO@octapharma.com" className="bold"> FR2DPO@octapharma.com</a>
        </p>
      </div>
      <div className={`${styles.col} ${styles.illu}`}>
        <img src={login} alt="illu-login" />
      </div>
    </div>
  );
}

export default Login;
