/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { animated, useSpring } from 'react-spring';
import { createPortal } from 'react-dom';
import styles from './modal.module.scss';

interface ILayout {
  children: JSX.Element,
  domNode?: string,
  hideCloseButton?: boolean,
  setModalState?: (a: boolean) => void;
}

const ModalStandalone = forwardRef(({
  children,
  domNode = 'modal',
  hideCloseButton = false,
  setModalState,
}: ILayout, ref): JSX.Element => {
  const modalRoot = document.getElementById(domNode);
  const containerChildren = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [toggleAnimation, setToggleAnimation] = useState(false);

  function toggle() {
    setOpen((state) => !state);
  }

  function close() {
    setToggleAnimation(false);
  }

  useImperativeHandle(ref, () => ({
    toggle: (): void => toggle(),
    open: (): void => setOpen(true),
    close: (): void => setOpen(false),
    isOpen: (): boolean => open,
  }), []);

  const spring = useSpring({
    opacity: toggleAnimation ? 1 : 0,
    onRest: (state) => {
      if (state.value.opacity === 0) toggle();
    },
  });

  useEffect(() => {
    if (!ref) console.error('ModalStandalone components need require a ref');
    // eslint-disable-next-line
  }, [ref]);

  useEffect(() => {
    if (!modalRoot) {
      console.error(`Can't find the dom element (#${domNode}) where this modal should be mount \nYou should add a div with id : "${domNode}" to public/index.html
     `);
    }
  // eslint-disable-next-line
  }, [modalRoot, domNode]);

  useEffect(() => {
    if (setModalState) setModalState(open);
    if (open) {
      setToggleAnimation(true);
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <div>
      {modalRoot && createPortal(
        <div>
          {(ref && open)
            && (
              <>
                <animated.div
                  style={{ ...spring }}
                  className={styles['overlay-modal']}
                  onClick={close}
                />
                <animated.div
                  style={{ ...spring }}
                  className={styles.modal}
                >
                  <animated.div
                    ref={containerChildren}
                  >
                    {children}
                    {!hideCloseButton
                      && (
                        <button
                          type="button"
                          className={styles.close}
                          onClick={close}
                        >
                          close
                        </button>
                      )}
                  </animated.div>
                </animated.div>
              </>
            )}
        </div>,
        modalRoot,
      )}
    </div>
  );
});

export default ModalStandalone;
