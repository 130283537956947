import React, { useEffect, useCallback } from 'react';
import {
  Routes, Route, Navigate,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Login from './pages/Auth/Login';
import { getProfileAction } from './actions/authActions';

import Forgot from './pages/Auth/Forgot';
import Reset from './pages/Auth/Reset';
import DoctorList from './pages/DoctorList/DoctorList';
import OctaList from './pages/OctaList/OctaList';
import Tool from './pages/Tool/Tool';
import SwitchIndex from './pages/Auth/Switch';
import setVhUnit from './utils/utils';
import ContactForm from './pages/ContactForm/ContactForm';

function App() {
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();

  const getProfile = useCallback(() => {
    getProfileAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    setVhUnit();
    if (token) {
      getProfile();
    }
  }, [token, getProfile]);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={<SwitchIndex />}
        />
        <Route
          path="/login"
          element={<Login />}
        />
        <Route
          path="/forgot"
          element={<Forgot />}
        />
        <Route
          path="/reset-password/:token"
          element={<Reset />}
        />
        <Route path="tool-octapharma">
          <Route path=":id" element={<Tool />} />
          <Route index element={<OctaList />} />
        </Route>
        <Route path="tool-doctor">
          <Route path=":id" element={<Tool />} />
          <Route index element={<DoctorList />} />
        </Route>
        <Route path="tool-contact/:id" element={<ContactForm />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
